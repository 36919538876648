// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contacts-js": () => import("./../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-gallery-index-js": () => import("./../src/pages/gallery/index.js" /* webpackChunkName: "component---src-pages-gallery-index-js" */),
  "component---src-pages-gallery-kuhni-js": () => import("./../src/pages/gallery/kuhni.js" /* webpackChunkName: "component---src-pages-gallery-kuhni-js" */),
  "component---src-pages-gallery-legla-js": () => import("./../src/pages/gallery/legla.js" /* webpackChunkName: "component---src-pages-gallery-legla-js" */),
  "component---src-pages-gallery-sekcii-js": () => import("./../src/pages/gallery/sekcii.js" /* webpackChunkName: "component---src-pages-gallery-sekcii-js" */),
  "component---src-pages-gallery-stulbishta-js": () => import("./../src/pages/gallery/stulbishta.js" /* webpackChunkName: "component---src-pages-gallery-stulbishta-js" */),
  "component---src-pages-gallery-vrati-js": () => import("./../src/pages/gallery/vrati.js" /* webpackChunkName: "component---src-pages-gallery-vrati-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-products-js": () => import("./../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-services-js": () => import("./../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */)
}

